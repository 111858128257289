<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { LightSwitchField } from '@vue-interface/light-switch-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, Catalog, Instance, SubscriberList, User } from 'types';
import { ref } from 'vue';
import FormLayout from '../components/FormLayout.vue';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import MessageGearsFolderSelectField from './MessageGearsFolderSelectField.vue';

const props = defineProps<{
    url: string;
    agency?: Agency;
    instance?: Instance;
    list?: SubscriberList;
    authUser: User;
    catalogs: Catalog[];
    instances: Instance[];
}>();

const instanceRef = ref<Instance|undefined>(props.instance);
const method = props.list?.id ? 'put' : 'post';

const form = useForm(method, props.url, {
    name: props.list?.name,
    instance_id: instanceRef.value?.id,
    mg_campaign_id: props.list?.mg_campaign_id,
    folder: props.list?.folder,
    options: {
        custom_fonts: props.list?.options.custom_fonts,
        enable_audience: props.list?.options.enable_audience ?? true,
        enable_cloudfront: props.list?.options.enable_cloudfront ?? true,
        enable_html: props.list?.options.enable_html ?? false,
        enable_sends: props.list?.options.enable_sends ?? true,
        mg_data_variables: props.list?.options.mg_data_variables,
        mg_from_name: props.list?.options.mg_from_name,
        mg_from_address: props.list?.options.mg_from_address,
        mg_reply_to_address: props.list?.options.mg_reply_to_address,
        smart_merge_tags: props.list?.options.smart_merge_tags,
    },
    elixr_catalog_id: props.list?.elixr_catalog_id,
});

function onSubmit() {
    form.submit();
}
</script>

<template>
    <FormLayout
        :submit="onSubmit"
        :button-label="!list?.id ? 'Submit' : 'Save'"
        :disabled="form.processing"
        :activity="form.processing">
        <Card>
            <FormField
                v-if="authUser.super_admin"
                label="Name"
                description="The name of the list."
                required>
                <InputField
                    id="name"
                    v-model="form.name"
                    name="name"
                    :error="form.errors.name"
                    @change="form.validate('name')" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="Catalog"
                description="The ListElixr catalog."
                required>
                <Autocomplete
                    id="elixr_catalog_id"
                    ref="catalog"
                    v-model="form.elixr_catalog_id"
                    name="elixr_catalog_id"
                    namespace="catalogs"
                    only="catalogs"
                    :response="catalogs"
                    :error="form.errors.elixr_catalog_id"
                    @update:model-value="form.validate('elixr_catalog_id')" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="MessageGears Instance"
                description="The MessageGears instance used to send emails."
                required>
                <Autocomplete
                    id="instance_id"
                    v-model="form.instance_id"
                    name="instance_id"
                    namespace="instances"
                    only="instances"
                    :response="instances"
                    :error="form.errors.instance_id"
                    @select="(e, model) => instanceRef = model"
                    @update:model-value="form.validate('instance_id')" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="MessageGears Template Folder"
                description="The MessageGears folder used to store templates.">
                <MessageGearsFolderSelectField
                    id="folder"
                    v-model="form.folder"
                    name="folder"
                    :error="form.errors.folder"
                    :instance="instanceRef"
                    @change="form.validate('folder')" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="MessageGears Campaign ID"
                description="The MessageGears marketing campaign ID."
                required>
                <InputField
                    id="mg_campaign_id"
                    v-model="form.mg_campaign_id"
                    name="mg_campaign_id"
                    :error="form.errors.mg_campaign_id"
                    @change="form.validate('mg_campaign_id')" />
            </FormField>
            <FormField
                label="From Name"
                description="The From Name for the list."
                required>
                <InputField
                    id="options.mg_from_name"
                    v-model="form.options.mg_from_name"
                    name="options.mg_from_name"
                    :error="(form.errors as Record<string,string>)['options.mg_from_name']"
                    @change="form.validate('options.mg_from_name' as any)" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="From Address"
                description="The From Email Address for the list."
                required>
                <InputField
                    id="options.mg_from_address"
                    v-model="form.options.mg_from_address"
                    name="options.mg_from_address"
                    :error="(form.errors as Record<string,string>)['options.mg_from_address']"
                    @change="form.validate('options.mg_from_address' as any)" />
            </FormField>
            <FormField
                label="Reply-To Address"
                description="The Reply-To Email Address for the list.">
                <InputField
                    id="options.mg_reply_to_address"
                    v-model="form.options.mg_reply_to_address"
                    name="options.mg_reply_to_address"
                    :error="(form.errors as Record<string,string>)['options.mg_reply_to_address']"
                    @change="form.validate('options.mg_reply_to_address' as any)" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="Sends"
                description="Enable the sends for this list.">
                <LightSwitchField
                    id="options.enable_sends"
                    v-model="form.options.enable_sends"
                    name="options.enable_sends"
                    :error="(form.errors as Record<string,string>)['options.enable_sends']"
                    class="light-switch-field-lg"
                    :off-value="false"
                    :on-value="true"
                    @update:model-value="form.validate('options.enable_sends' as any)" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="Audience"
                description="Enable the audience for this list.">
                <LightSwitchField
                    id="options.enable_audience"
                    v-model="form.options.enable_audience"
                    name="options.enable_audience"
                    :error="(form.errors as Record<string,string>)['options.enable_audience']"
                    class="light-switch-field-lg"
                    :off-value="false"
                    :on-value="true"
                    @update:model-value="form.validate('options.enable_audience' as any)" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="Cloudfront"
                description="Enable Cloudfront Image CDN. If enabled, the client images in the HTML will be replaced with Cloudfront URL's. This will infer additional billings to the client. Use with caution!">
                <LightSwitchField
                    id="options.enable_cloudfront"
                    v-model="form.options.enable_cloudfront"
                    name="options.enable_cloudfront"
                    :error="(form.errors as Record<string,string>)['options.enable_cloudfront']"
                    class="light-switch-field-lg"
                    :off-value="false"
                    :on-value="true"
                    @update:model-value="form.validate('options.enable_cloudfront' as any)" />
            </FormField>
            <FormField
                v-if="authUser.super_admin"
                label="HTML Content"
                description="Enable HTML send types for this list.">
                <LightSwitchField
                    id="options.enable_html"
                    v-model="form.options.enable_html"
                    name="options.enable_html"
                    :error="(form.errors as Record<string,string>)['options.enable_html']"
                    class="light-switch-field-lg"
                    :off-value="false"
                    :on-value="true"
                    @update:model-value="form.validate('options.enable_html' as any)" />
            </FormField>
        </Card>
    </FormLayout>
</template>