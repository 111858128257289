<script lang="ts" setup>
import { InformationCircleIcon, MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';
import { InputField } from '@vue-interface/input-field';
import { SelectField } from '@vue-interface/select-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, AgencyUser, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import Tab from './Tab.vue';
import Tabs from './Tabs.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    agency: Agency;
    agencyUsers?: (User & { pivot: AgencyUser })[];
    agencyUser?: AgencyUser;
    user?: User;
}>();

const userRef = ref(props.user);
const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const method = props.agencyUser ? 'put' : 'post';

const url = props.agencyUser
    ? route('agencies.users.update', {
        agency: props.agency?.id,
        user: props.agencyUser.user_id
    })
    : route('agencies.users.store', {
        agency: props.agency?.id
    });

const page = usePage<{
    queryParams: Record<string,any>
}>();

const form = useForm(method, url, {
    email: null,
    name: null,
    role: props.agencyUser?.role,
    user_id: props.agencyUser?.user_id,
    abilities: props.agencyUser?.abilities,
    queryParams: page.props.queryParams
});

function onSubmit() {
    activity.value = true;

    form.errors = {};
    form.submit({
        only: ['agency', 'users', 'agencyUsers'],
        preserveScroll: true,
        onSuccess() {
            emit('success');
        },
        onError(errors) {
            emit('error', errors);
        },
        onFinish() {
            activity.value = false;
        }
    });
}

function onSelect(e: MouseEvent, model: User) {
    userRef.value = model;
    form.email = null;
    form.user_id = userRef.value.id;
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ agencyUser?.role ? 'Edit Permissions' : `Assign User to Agency` }}
            </h2>
        </div>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            :button-label="agencyUser?.role ? 'Save' : 'Submit'"
            :disabled="activity"
            :activity="activity">
            <Tabs
                v-if="!agencyUser?.role"
                flex
                class="mt-6">
                <Tab
                    header="Search for User"
                    :icon="MagnifyingGlassIcon">
                    <FormField
                        label="Name"
                        description="The user you wish to assign to the agency."
                        required
                        block>
                        <Autocomplete
                            id="name"
                            v-model="form.name"
                            name="name"
                            namespace="agency-users"
                            only="agencyUsers"
                            :response="agencyUsers"
                            :error="form.errors?.name"
                            :selector="(model: User) => model.id === form.user_id"
                            @select="onSelect" />
                    </FormField>
                </Tab>
                <Tab
                    header="Create User"
                    :icon="UserPlusIcon">
                    <div
                        class=" mt-4 flex items-center gap-3 rounded bg-amber-50 p-3 text-base shadow dark:bg-violet-600">
                        <InformationCircleIcon class="h-10 w-10 text-neutral-800 dark:text-white" />
                        <p>
                            You are creating a new user and assigning them to this agency.
                        </p>
                    </div>

                    <FormField
                        label="Name"
                        description="The name of the new user."
                        required
                        block>
                        <InputField
                            id="name"
                            ref="email"
                            v-model="form.name"
                            name="name"
                            :error="form.errors?.name" />
                    </FormField>   
                    <FormField
                        label="Email"
                        description="The email of the new user."
                        required
                        block>
                        <InputField
                            id="email"
                            ref="email"
                            v-model="form.email"
                            name="email"
                            :error="form.errors?.email" />
                    </FormField>
                </Tab>
            </Tabs>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the agency."
                required
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :error="form.errors?.role">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
            <FormField
                v-if="form.role === 'user'"
                label="Abilities"
                description="The abilities give users privileges in the app."
                block>
                <UserAbilitiesField v-model="form.abilities" />
            </FormField>
        </FormLayout>
    </div>
</template>