<script lang="ts" setup generic="T extends BaseModel">
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import { SelectField } from '@vue-interface/select-field';
import { format } from 'date-fns';
import { debounce } from 'lodash-es';
import type { BaseModel, LengthAwarePaginator } from 'types';
import type { Component, RenderFunction } from 'vue';
import { capitalize, computed, onBeforeMount, onBeforeUnmount, reactive, watch, watchEffect } from 'vue';
import ListGroup from '../components/ListGroup.vue';
import ListGroupItem from '../components/ListGroupItem.vue';
import { useEcho, type UseEcho } from '../composables/useEcho';
import ActionButton from './ActionButton.vue';
import SearchField from './filters/SearchField.vue';
import Header from './Header.vue';
import Pagination from './Pagination.vue';
import Skeleton from './Skeleton.vue';

export type LaraveLinks = {
    label: string,
    url: string | null,
    active: boolean
}[]

type EchoContext = {
    reload: () => void;
    models: T[];
}

type EchoOld = {
    listen: (context: EchoContext) => void;
    stopListening: (context: EchoContext) => void;
}

const props = withDefaults(defineProps<{
    response?: LengthAwarePaginator<T>;
    singular?: string;
    plural?: string;
    namespace?: string;
    only?: string|string[];
    title?: (model: T) => string|undefined;
    description?: (model: T) => string|undefined;
    params?: Record<string, any>;
    routes?: {
        index?: string;
        create?: string;
        delete?: (model: T) => string;
        edit?: (model: T) => string;
        show?: (model: T) => string;
    };
    can?: {
        update?: (model: T) => boolean|undefined;
        view?: (model: T) => boolean|undefined;
        delete?: (model: T) => boolean|undefined;
        create?: boolean;
    };
    filters?: boolean;
    paginate?: boolean;
    searchable?: boolean;
    sortable?: boolean;
    deleteLabel?: string;
    deleteIcon?: Component;
    defaultSort?: string;
    sortOptions?: {
        label: string;
        value: string|undefined;
    }[];
    header?: string;
    headerDescription?: string;
    icons?: {
        default?: Component | RenderFunction | string;
        list?: (model: T) =>  Component | RenderFunction | string;
        header?: Component | RenderFunction | string;
        empty?: Component | RenderFunction | string;
    };
    badges?: (model: T) => string[];
    size?: 'sm' | 'md' | 'lg';
    echo?: EchoOld;
    echoNew?: (echo: UseEcho, context: EchoContext) => void;
}>(), {
    response: undefined,
    singular: undefined,
    plural: undefined,
    namespace: undefined,
    only: undefined,
    title: (model: T) => {
        if('name' in model) {
            return model.name as string;
        }

        if('title' in model) {
            return model.title as string;
        }

        return model.id?.toString();
    },
    description: (model: T) => `Updated on ${model.updated_at && format(new Date(model.updated_at), 'PPPp')}`,
    routes: undefined,
    can: () => ({
        create: true,
        delete: () => true,
        update: () => true,
        view: () => true,
    }),
    params: () => ({}),
    badges: () => [],
    sortOptions: () => [
        { label: 'Most Recent', value: 'created_at,desc' },
        { label: 'Oldest First', value: 'created_at,asc' },
        { label: 'Alphabetically (asc)', value: 'name,asc' },
        { label: 'Alphabetically (desc)', value: 'name,desc' },
    ],
    filters: true,
    paginate: true,
    defaultSort: undefined,
    deleteLabel: 'delete',
    deleteIcon: undefined,
    header: undefined,
    headerDescription: undefined,
    icons: () => ({
        default: undefined,
        list: undefined,
        header: undefined,
        empty: undefined,
    }),
    size: 'lg',
    searchable: true,
    sortable: true,
    echo: undefined,
    echoNew: undefined,
});

const page = usePage<{
    queryParams: Record<string,any>
}>();

const queryParams = computed(() => {
    if(props.namespace) {
        return Object.assign({}, page.props.queryParams?.[props.namespace], props.params);
    }

    return Object.assign({}, page.props.queryParams, props.params);
});

const singularName = computed(() => props.singular?.toLowerCase());
const pluralName = computed(() => props.plural?.toLowerCase());
const formData = reactive<Record<string,any>>({
    order: undefined,
    q: undefined,
});

const order = computed(() => {
    return queryParams.value.order ?? props.defaultSort ?? props.sortOptions[0]?.value;
});

watchEffect(() => {
    formData.order = order.value;
    formData.q = queryParams.value.q;
});

const only = computed(() => {
    if(Array.isArray(props.only)) {
        return props.only;
    }

    if(props.only) {
        return [props.only];
    }

    if(pluralName.value) {
        return [pluralName.value];
    }
    
    return [];
});

const requestData = computed(() => {
    if(!props.namespace) {
        return formData;
    }

    return { [props.namespace]: formData };
});

const debounced = debounce((key: string, modelValue: any) => {
    formData[key] = modelValue;
}, 333);

const icons = computed(() => ({
    default: props.icons.default,
    list: (model: T) => props.icons?.list?.(model) ?? props.icons.default,
    header: props.icons.header ?? props.icons.default,
    empty: props.icons.empty ?? props.icons.default,
}));

function reload() {
    router.reload({
        data: requestData.value,
        only: only.value
    });
}

function onClickDelete(model: T) {
    if(!confirm(`Are you sure you want to ${props.deleteLabel} this ${singularName.value}?`) || !props.routes?.delete) {
        return;
    }

    const form = useForm({
        queryParams: page.props.queryParams
    });

    form.delete(props.routes.delete(model), {
        preserveScroll: true,
        only: only.value
    });
}

const sockets = useEcho();

defineExpose({
    reload
});

watch(formData, reload);

onBeforeMount(() => {
    watch(() => props.response, (value, oldValue) => {
        if(oldValue) {
            // @todo - remove this after refactoring all echo in searchablelist groups
            props.echo?.stopListening({ models: oldValue.data, reload });
            sockets.dispose();
        }

        if(value) {
            // @todo - remove this after refactoring all echo in searchablelist groups
            props.echo?.listen({ models: value.data, reload });
            // @todo - rename echo-new prop
            props.echoNew?.(sockets, { models: value.data, reload });
        }
    });
});

// @todo - remove this after refactoring all echo in searchablelist groups
onBeforeUnmount(() => {
    props.echo?.stopListening({
        reload,
        models: props.response?.data ?? []
    });
});
</script>

<template>
    <ListGroup>
        <template #header>
            <Header
                v-bind="{
                    title: header ?? (pluralName && capitalize(pluralName)),
                    icon: icons.header,
                    size,
                    description: headerDescription,
                }">
                <template #actions>
                    <slot name="actions" />

                    <slot
                        name="create-action">
                        <Link
                            v-if="routes?.create && can.create"
                            :href="routes?.create"
                            class="btn btn-primary capitalize">
                            Create {{ singularName }}
                        </Link>
                    </slot>
                </template>
            </Header>

            <slot
                v-if="filters"
                name="filters">
                <div class="mb-4 flex items-end">
                    <div class="flex flex-1 items-end gap-2">
                        <slot
                            v-if="searchable"
                            name="search">
                            <SearchField
                                :model-value="formData.q"
                                class="w-full max-w-80"
                                @reset="formData.q = undefined"
                                @update:model-value="value => debounced('q', value)" />
                        </slot>
                        <slot
                            name="left-filters"
                            v-bind="{ formData, debounced }" />
                    </div>
                    <div class="flex flex-1 justify-end gap-2">
                        <slot
                            name="right-filters"
                            v-bind="{ formData, debounced }" />
                        <SelectField
                            v-if="sortable"
                            id="sort"
                            v-model="formData.order"
                            class="min-w-40"
                            label="Sort By">
                            <option
                                v-for="option in sortOptions"
                                :key="option.value"
                                :value="option.value">
                                {{ option.label }}
                            </option>
                        </SelectField>
                    </div>
                </div>
            </slot>
        </template>

        <div v-if="!response">
            <Skeleton class="h-16 rounded-none first:rounded-t last:rounded-b" />
        </div>

        <ListGroupItem v-else-if="!response?.data.length">
            <template #icon>
                <Component
                    :is="icons.empty"
                    class="size-8" />
            </template>
            <slot name="no-results">
                No results found.
            </slot>
        </ListGroupItem>

        <div v-else>
            <ListGroupItem
                v-for="model in response.data"
                :key="model.id"
                :can-view="can?.view?.(model)"
                :href="routes?.show?.(model)"
                :icon="icons.list(model)">
                <slot :model="model" />

                <template #icon>
                    <slot
                        name="icon"
                        :model="model" />
                </template>

                <template #title>
                    <slot
                        name="title"
                        :model="model">
                        <div v-if="title">
                            {{ title(model) }}
                        </div>
                    </slot>
                </template>

                <template #badges>
                    <slot
                        name="badges"
                        :model="model">
                        <template v-for="badge in badges(model)">
                            <div
                                v-if="badge"
                                :key="badge"
                                class="badge bg-sky-500">
                                {{ badge }}
                            </div>
                        </template>
                    </slot>
                </template>

                <template #description>
                    <slot
                        name="description"
                        :model="model">
                        <div
                            v-if="description"
                            class="my-1">
                            {{ description(model) }}
                        </div>
                    </slot>
                </template>

                <template #tags>
                    <slot
                        name="tags"
                        :model="model" />
                </template>

                <template #list-actions>
                    <slot
                        name="before-list-action"
                        :model="model" />
                    <slot
                        name="list-actions"
                        :model="model">
                        <ActionButton
                            size="sm">
                            <Link
                                v-if="routes?.edit && can.update?.(model)"
                                :href="routes?.edit(model)"
                                class="group flex items-center capitalize">
                                <PencilSquareIcon class="my-1 mr-3 size-5" /> Edit {{ singularName }}
                            </Link>

                            <slot
                                name="list-actions-links"
                                :model="model" />

                            <hr v-if="routes?.edit && can.update?.(model) && routes?.delete && can.delete?.(model)">

                            <button
                                v-if="routes?.delete && can.delete?.(model)"
                                class="group flex items-center capitalize"
                                @click="() => onClickDelete(model)">
                                <component
                                    :is="deleteIcon ?? TrashIcon"
                                    class="my-1 mr-3 size-5" />
                                {{ deleteLabel }} {{ singularName }}
                            </button>
                        </ActionButton>
                        <slot
                            name="after-list-action"
                            :model="model" />
                    </slot>
                </template>
            </ListGroupItem>
        </div>
        <template #footer>
            <slot name="footer">
                <Pagination
                    v-if="response"
                    :response="response"
                    :only="only" />
            </slot>
        </template>
    </ListGroup>
</template>