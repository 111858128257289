<script setup lang="ts" generic="T">
import { Link } from '@inertiajs/vue3';
import type { LengthAwarePaginator } from 'types';

defineProps<{
    response: LengthAwarePaginator<T>;
    only?: string[];
}>();

function label(value: string) {
    return value.replace(' Previous', '').replace('Next ', '');
}
</script>

<template>
    <nav
        v-if="response.last_page > 1"
        class="mt-4">
        <ul class="pagination justify-content-center">
            <li
                v-for="(link, i) in response.links"
                :key="i"
                class="page-item"
                :class="{
                    active: link.active,
                    disabled: !link.url
                }">
                <template v-if="link.url">
                    <Link
                        :href="link.url"
                        :only="only"
                        :preserve-scroll="true"
                        class="page-link">
                        <span v-html="label(link.label)" />
                    </Link>
                </template>
                <template v-else>
                    <span
                        class="page-link"
                        v-html="label(link.label)" />
                </template>
            </li>
        </ul>
    </nav>
</template>