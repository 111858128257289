import { router } from '@inertiajs/vue3';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { type App } from 'vue';
import channels from '../channels';

export interface EchoOptions {
    pusher: {
        host?: string,
        port?: number,
        force_tls?: boolean
    }
};

export type UseEchoDeclarationSchema = Record<string, Record<any,UseEchoEvents>>;
export type EchoChannelName = 'private' | 'channel' | 'persistence';
export type UseEchoEvents = Record<any, UseEchoCallback>
export type UseEchoCallback = (...args: any[]) => void;

export type UseEchoDeclarationFunction<T = any> = (context: T) => UseEchoDeclarationSchema;

const client = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    authEndpoint: '/broadcasting/auth',
    userAuthentication: {
        transport: 'ajax',
        endpoint: '/broadcasting/user-auth'
    },
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: import.meta.env.VITE_PUSHER_USE_TLS === 'true',
    // scheme: import.meta.env.VITE_PUSHER_SCHEME,
    disableStats: import.meta.env.VITE_PUSHER_DISABLE_STATS !== 'false',
});

export type Broadcaster = 'null'|'pusher'|'reverb';

const broadcaster: Broadcaster = import.meta.env.VITE_BROADCAST_DRIVER;

export const echo = new Echo({ broadcaster, client });

export default (Vue: App, props: any) => {
    router.on('before', (event) => {
        event.detail.visit.headers['X-Socket-Id'] = echo.socketId();
    });
    
    if(props.authUser) {
        channels({ echo, props });
    }
};