<script lang="ts" setup>
import { SelectField as BaseField } from '@vue-interface/select-field';
import type { MgDataVariable } from 'types';
import { computed, watchEffect } from 'vue';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    errors: any,
    index: number,
    modelValue: string
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string|undefined]
}>();

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isDefault = computed(() => {
    return model.value === props.item.defaultValue;
});

watchEffect(() => {
    emit('update:formattedValue', model.value);

    const readableValue = isDefault.value
        ? props.item.defaultLabel
        : props.item.options?.find(i => i.value === model.value)?.label;
    
    emit('update:readableValue', readableValue);
});

</script>

<template>
    <div class="flex items-start gap-x-2">
        <BaseField
            :id="id"
            v-model="model"
            :name="name"
            :error="errors[name]"
            class="w-full">
            <option :value="item.defaultValue">
                {{ item.defaultLabel }}
            </option>
            <option
                v-for="{value, label} in item.options"
                :key="value"
                :value="value">
                {{ label }}
            </option>
        </BaseField>
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>