<script lang="ts" setup>
import { InformationCircleIcon, MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import { usePage } from '@inertiajs/vue3';
import { InputField } from '@vue-interface/input-field';
import { SelectField } from '@vue-interface/select-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, ListUser, SubscriberList, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import Autocomplete from './Autocomplete.vue';
import Banner from './Banner.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import Tab from './Tab.vue';
import Tabs from './Tabs.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    agency?: Agency,
    list: SubscriberList;
    listUser?: ListUser;
    listUsers?: (User & { pivot: ListUser })[];
    user?: User;
}>();

const userRef = ref(props.user);
const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const method = props.listUser ? 'put' : 'post';

const url = props.listUser
    ? route('agencies.lists.users.update', {
        agency: props.agency?.id,
        list: props.list.id,
        user: props.listUser.user_id
    })
    : route('agencies.lists.users.store', {
        agency: props.agency?.id,
        list: props.list.id
    });

const page = usePage<{
    queryParams: Record<string,any>
}>();

const form = useForm(method, url, {
    email: null,
    name: null,
    role: props.listUser?.role,
    user_id: props.listUser?.user_id,
    abilities: props.listUser?.abilities,
    queryParams: page.props.queryParams
});

function onSubmit() {
    activity.value = true;

    form.errors = {};
    form.submit({
        only: ['list', 'users', 'listUsers'],
        preserveScroll: true,
        onSuccess() {
            emit('success');
        },
        onError(errors) {
            emit('error', errors);
        },
        onFinish() {
            activity.value = false;
        }
    });
}

function onSelect(e: MouseEvent, model: User) {
    userRef.value = model;
    form.email = null;
    form.user_id = model.id;
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ listUser?.user ? 'Edit Permissions' : 'Assign User' }}
            </h2>
        </div>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            :button-label="listUser?.role ? 'Save' : 'Submit'"
            :disabled="activity"
            :activity="activity">
            <Tabs
                v-if="!listUser"
                flex
                class="mt-6">
                <Tab
                    header="Search for User"
                    :icon="MagnifyingGlassIcon">
                    <FormField
                        label="Name"
                        description="The user you wish to assign to the list."
                        block>
                        <Autocomplete
                            id="name"
                            v-model="form.name"
                            name="name"
                            namespace="list-users"
                            only="listUsers"
                            :response="listUsers"
                            :error="form.errors?.name"
                            :selector="(model: User) => model.id === form.user_id"
                            @select="onSelect" />
                    </FormField>
                </Tab>
                <Tab
                    header="Create User"
                    :icon="UserPlusIcon">
                    <Banner
                        class="!bg-amber-300 dark:!bg-violet-600 text-base">
                        <InformationCircleIcon class="size-8 flex-shrink-0" /> 
                        You are creating a new user and assigning them to this list.
                    </Banner>

                    <FormField
                        label="Name"
                        description="The name of the new user."
                        block>
                        <InputField
                            id="name"
                            v-model="form.name"
                            name="name"
                            :error="form.errors?.name" />
                    </FormField>       
                    <FormField
                        label="Email"
                        description="The email of the new user."
                        block>
                        <InputField
                            id="email"
                            ref="email"
                            v-model="form.email"
                            name="email"
                            :error="form.errors?.email" />
                    </FormField>
                </Tab>
            </Tabs>
            <div>
                <FormField
                    label="Role"
                    description="The role will determine if the user can manage the list."
                    block>
                    <SelectField
                        id="role"
                        v-model="form.role"
                        name="role"
                        :error="form.errors?.role">
                        <option value="admin">
                            Admin
                        </option>
                        <option value="user">
                            User
                        </option>
                    </SelectField>
                </FormField>
                <FormField
                    v-if="form.role === 'user'"
                    label="Abilities"
                    description="The abilities give users privileges in the app."
                    block>
                    <UserAbilitiesField v-model="form.abilities" />
                </FormField>
            </div>
        </FormLayout>
    </div>
</template>