import type { NullChannel, PusherChannel } from 'laravel-echo/dist/channel';
import { onScopeDispose } from 'vue';
import { echo } from '../plugins/Echo';

export type UseEcho = {
    dispose: () => void;
    channel: (channel: string) => PusherChannel|NullChannel;
    private: (channel: string) => PusherChannel|NullChannel;
}
export function useEcho(): UseEcho {
    const channels: (PusherChannel|NullChannel)[] = [];

    function dispose() {
        for(const channel of channels) {
            channel.unsubscribe();
        }
    }

    onScopeDispose(dispose);

    return {
        dispose,
        channel(channel: string) {
            const instance = echo.channel(channel);

            instance.subscribe();

            channels.push(instance);

            return instance;
        },
        private(channel: string) {
            const instance = echo.private(channel);

            instance.subscribe();

            channels.push(instance);

            return instance;
        }
    };
}