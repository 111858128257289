<script lang="ts" setup generic="ModelValue, Model extends BaseModel">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { Spinner } from '@vue-interface/activity-indicator';
import { InputField } from '@vue-interface/input-field';
import { debounce } from 'lodash-es';
import { parse } from 'qs';
import type { BaseModel } from 'types';
import { computed, onMounted, ref, watch } from 'vue';

const props = withDefaults(defineProps<{
    response?: Model[];
    namespace?: string;
    only?: string|string[];
    badges?: (model: Model) => (string|undefined)[];
    disabled?: boolean;
    errors?: Record<string,string[]>;
    error?: any;
    height?: string;
    id?: string;
    label?: (model: Model) => string|number;
    value?: (model: Model) => any;
    find?: (model: Model) => boolean;
    description?: (model: Model) => string;
    maxHeight?: string;
    name?: string;
    params?: Record<string, any>;
    modelValue?: ModelValue;
}>(), {
    response: () => ([]),
    namespace: undefined,
    badges: () => () => ([]),
    disabled: false,
    only: undefined,
    errors: undefined,
    error: undefined,
    height: undefined,
    maxHeight: '24rem',
    id: undefined,
    label: (model: Model) => {
        return 'name' in model ? model.name as string : model.id;
    },
    value: (model: Model) => model.id,
    find: undefined,
    description: undefined,
    name: undefined,
    params: () => ({}),
    modelValue: undefined,
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void,
    (e: 'blur', event: InputEvent): void,
    (e: 'change', event: InputEvent): void,
    (e: 'select', event: MouseEvent, value: Model): void
}>();

const activity = ref(false);
const hasFocus = ref(false);
const field = ref();
const menu = ref();
// const currentModel = ref<T>();
const container = ref<HTMLDivElement>();
const selectButtons = ref<HTMLButtonElement[]>([]);
let relatedTarget = ref();

const queryParams = computed(() => {
    const parsed = parse(location.search.replace(/^\?/, ''));

    if(props.namespace) {
        return Object.assign({}, parsed[props.namespace], props.params);
    }

    return Object.assign({}, parsed, props.params);
});

const match = computed(() => {
    return props.response.find(
        props.find ?? (model => model.id === props.modelValue)
    );
});

const label = computed(() => {
    return q.value ?? (match.value && props.label(match.value));
});

const q = ref(queryParams.value.q);

const requestData = computed(() => {
    const data = {
        q: q.value,
        ...props.params
    };

    if(!props.namespace) {
        return data;
    }

    return { [props.namespace]: data };
});

const only = computed(() => {
    if(Array.isArray(props.only)) {
        return [...props.only];
    }

    if(props.only) {
        return [props.only];
    }

    if(props.namespace) {
        return [props.namespace];
    }
    
    return [];
});

async function request() {
    activity.value = true;
    
    router.reload({
        data: requestData.value,
        only: only.value,
        onFinish() {
            activity.value = false;
        }
    });
}

watch(q, debounce(request, 300));

onMounted(() => {
    request();
});

function select(model: Model) {
    q.value = undefined;

    emit('update:modelValue', props.value(model));
}

function focusPrev(index: number) {
    const child = menu.value?.children[
        (index === -1 ? menu.value.children.length : index) - 1
    ];

    if(child) {
        child.focus();
    }
    else {
        field.value.$el?.querySelector('input')?.focus();
    }
}

function focusNext(index: number) {
    const child = menu.value?.children[index + 1];

    if(child) {
        child.focus();
    }
    else {
        field.value.focus();
    }
}

function onMouseDown(e: MouseEvent, button: HTMLButtonElement) {
    relatedTarget.value = button;
}

function onBlur(e: FocusEvent) {
    if(!e.relatedTarget || !container.value?.contains(e.relatedTarget as Node)) {
        hasFocus.value = false;
    }
}

async function onFocus() {
    hasFocus.value = true;
}

function onInput(event: InputEvent) {
    q.value = (event.target as HTMLInputElement)?.value;

    emit('change', event);
}

function onKeyup(e: KeyboardEvent) {
    const index = menu.value && Array.from(menu.value.children).indexOf(
        menu.value.querySelector(':focus, :active')
    );

    if(e.key === 'ArrowDown') {
        focusNext(index);
    }
    else if(e.key === 'ArrowUp') {
        focusPrev(index);
    }
}

function onSelect(e: MouseEvent, model: Model) {
    relatedTarget.value = undefined;
    hasFocus.value = false;
    emit('select', e, model);
    if(!e.defaultPrevented) {
        select(model);
    }
}
</script>

<template>
    <div
        ref="container"
        class="relative"
        @keyup="onKeyup">
        <InputField
            :id="id"
            ref="field"
            autocomplete="off"
            :indicator="Spinner"
            indicator-size="xs"
            :model-value="label"
            :name="name"
            :disabled="disabled"
            :activity="activity"
            :errors="errors"
            :error="error"
            @focus="onFocus"
            @blur="onBlur"
            @input="onInput">
            <template #icon>
                <MagnifyingGlassIcon class="h-6 w-6" />
            </template>
        </InputField>
        <div
            v-if="hasFocus && response"
            ref="menu"
            class="absolute left-0 top-10 z-10 w-full overflow-auto bg-white shadow-lg dark:bg-neutral-950"
            :style="{maxHeight, height}">
            <template v-if="!!response?.length">
                <button
                    v-for="(item, i) in response"
                    :id="`menu-button-${i}`"
                    :key="i"
                    ref="selectButtons"
                    type="button"
                    tabindex="2"
                    :class="{ 'text-rose-500 dark:text-rose-500': modelValue === item.id }"
                    class="text-left flex w-full items-center p-2 outline-none hover:bg-neutral-100 focus:bg-rose-500 dark:focus:text-neutral-200 focus:text-neutral-200 active:bg-rose-500 active:text-neutral-200 dark:text-neutral-200 dark:hover:bg-neutral-800 dark:hover:focus:bg-rose-500"
                    @mousedown="e => onMouseDown(e, selectButtons[i])"
                    @click="e => onSelect(e, item)"
                    @blur="onBlur">
                    <div class="flex items-center gap-2">
                        <slot :model="item">
                            <div class="flex flex-col">
                                {{ props.label(item) }}
                                <div
                                    v-if="description"
                                    class="text-sm dark:text-neutral-500">
                                    {{ description(item) }}
                                </div>
                            </div>
                            <slot
                                name="badges"
                                :model="item">
                                <template v-for="badge in badges(item)">
                                    <div
                                        v-if="badge"
                                        :key="badge"
                                        class="flex h-4 capitalize items-center rounded bg-rose-500 p-1 !text-xs text-neutral-200">
                                        {{ badge }}
                                    </div>
                                </template>
                            </slot>
                        </slot>
                    </div>
                </button>
            </template>
            <div
                v-else
                class="flex p-2">
                <slot
                    name="no-results"
                    v-bind="{clear: () => hasFocus = false}">
                    No records found.
                </slot>
            </div>
        </div>
    </div>
</template>