<script lang="ts" setup>
import { BuildingOfficeIcon, ClipboardDocumentListIcon, EnvelopeIcon, ExclamationCircleIcon, InformationCircleIcon, LockOpenIcon, PencilSquareIcon, PlusCircleIcon, Square2StackIcon, TrashIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router, useForm } from '@inertiajs/vue3';
import { SlidePanel, useSlidePanels } from '@vue-interface/slide-panel';
import { format } from 'date-fns';
import type { Agency, AgencyUser, Audit, Can, LengthAwarePaginator, ListUser, SubscriberList, User } from 'types';
import { ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import { useToast } from 'vue-toastification';
import AdminLayout from '../Layouts/AdminLayout.vue';
import ActionButton from '../components/ActionButton.vue';
import Banner from '../components/Banner.vue';
import DetailsView from '../components/DetailsView.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import UserAgencyForm from '../components/UserAgencyForm.vue';
import UserListForm from '../components/UserListForm.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { titleCase } from '../composables/titleCase';
import { useEcho } from '../composables/useEcho';

const props = defineProps<{
    agencyCan: Can;
    agencies?: LengthAwarePaginator<Agency & { pivot: AgencyUser }>;
    authUser: User;
    listCan: Can;
    lists?: LengthAwarePaginator<SubscriberList & { pivot: ListUser }>;
    user: User;
    userAgencies?: (Agency & { pivot: AgencyUser })[]
    userLists?: (SubscriberList & { pivot: ListUser })[],
    audits?: LengthAwarePaginator<Audit>;
    queryParams?: Record<string,any>;
}>();

defineOptions({
    layout: AdminLayout
});

const echo = useEcho();
const toast = useToast();

const { open, close } = useSlidePanels();

const agencyUser = ref<AgencyUser|undefined>();
const agenciesSearch = ref<ComponentExposed<typeof SearchableListGroup<Agency>>>();

const listUser = ref<ListUser|undefined>();
const listsSearch = ref<ComponentExposed<typeof SearchableListGroup>>();
    
function onClickSendActivation(user: User) {
    if(confirm('Are you sure you want to send an activation email?')) {
        router.put(route('users.activation-email', { user: user.id }));
    }
}

function onClickCopyActivationUrl(user: User) {
    navigator.clipboard.writeText(user.activation_url).then(() => {
        toast.info('The activation link has been copied to your clipboard!');
    }, () => {
        toast.error('Unable to copy the activation link to your clipboard.');
    });
}

function onClickUpdateAgencyUser(agency: Agency & { pivot: AgencyUser }) {
    agencyUser.value = agency.pivot;
    
    open('user-agency-form');
}

function onClickUpdateListUser(list: SubscriberList & { pivot: ListUser }) {
    listUser.value = list.pivot;

    open('user-list-form');
}

function onClickRemoveAgencyUser(agency: Agency & { pivot: AgencyUser }) {
    if(confirm(`Are you sure you want to remove ${agency.name} from ${props.user.email}?`)) {
        const form = useForm({
            queryParams: props.queryParams
        });

        form.submit('delete', route('users.agencies.destroy', {
            user: props.user.id,
            agency: agency.id
        }), {
            preserveScroll: true,
            only: ['audits', 'user', 'agencies', 'userAgencies'],
        });
    }
}

function onClickRemoveListUser(list: SubscriberList & { pivot: ListUser }) {
    if(confirm(`Are you sure you want to remove ${list.name} from ${props.user.email}?`)) {
        const form = useForm({
            queryParams: props.queryParams
        });

        form.submit('delete', route('users.lists.destroy', {
            user: props.user.id,
            list: list.id
        }), {
            preserveScroll: true,
            only: ['audits', 'user', 'lists', 'userLists'],
        });
    }
}

function onClickDeleteUser(user: User) {
    if(confirm(`Are you sure you want to delete ${user.email}?`)) {
        router.delete(route('users.destroy', { user: user.id }));
    }
}

function impersonate(user: User) {
    if(confirm(`Are you sure you want to login as ${user.name}?`)) {
        window.location.href = `/impersonate/${user.id}`;
    }
}

echo.private(`App.Models.User.${props.user.id}`)
    .listen('.UserUpdated', () => router.reload({ only: ['user'] }))
    .listen('.UserDeleted', () => router.reload())
    .listen('.AgencyUserCreated', () => router.reload({ only: ['user', 'agencies', 'userAgencies'] }))
    .listen('.AgencyUserUpdated', () => router.reload({ only: ['user', 'agencies', 'userAgencies'] }))
    .listen('.AgencyUserDeleted', () => router.reload({ only: ['user', 'agencies', 'userAgencies'] }))
    .listen('.ListUserCreated', () => router.reload({ only: ['user', 'lists', 'userLists'] }))
    .listen('.ListUserUpdated', () => router.reload({ only: ['user', 'lists', 'userLists'] }))
    .listen('.ListUserDeleted', () => router.reload({ only: ['user', 'lists', 'userLists'] }))
    .listen('.AuditCreated', () => router.reload({ only: ['audits'] }))
    .listen('.AuditUpdated', () => router.reload({ only: ['audits'] }))
    .listen('.AuditDeleted', () => router.reload({ only: ['audits'] }));
</script>

<template>
    <Show
        :icon="UserIcon"
        :title="user.name"
        :description="user.email"
        :badges="user.super_admin ? ['Super Admin'] : []">
        <template #actions>
            <ActionButton>
                <Link
                    :href="route('users.edit', { user: user.id })"
                    class="group flex items-center">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit User
                </Link>
                <button
                    v-if="user.activated_at && user.id !== authUser.id"
                    class="group flex items-center"
                    @click="impersonate(user)">
                    <LockOpenIcon class="my-1 mr-3 h-5 w-5" /> Login as User
                </button>
                <button
                    v-if="!user.activated_at"
                    class="group flex items-center"
                    @click="onClickSendActivation(user)">
                    <EnvelopeIcon class="my-1 mr-3 h-5 w-5" /> Re-Send Activation Email
                </button>
                <button
                    v-if="!user.activated_at"
                    class="group flex items-center"
                    @click="onClickCopyActivationUrl(user)">
                    <Square2StackIcon class="my-1 mr-3 h-5 w-5" /> Copy Activation Link
                </button>
                <button
                    v-if="!user.super_admin"
                    class="group flex items-center"
                    @click="open('user-agency-form')">
                    <BuildingOfficeIcon class="my-1 mr-3 h-5 w-5" /> Assign to Agency
                </button>
                <button
                    v-if="!user.super_admin"
                    class="group flex items-center"
                    @click="open('user-list-form')">
                    <UserGroupIcon class="my-1 mr-3 h-5 w-5" /> Assign to List
                </button>
                <hr>
                <button
                    class="group flex items-center"
                    @click="onClickDeleteUser(user)">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete User
                </button>
            </ActionButton>
        </template>

        <Banner
            v-if="!user.super_admin && !user.has_assignments"
            class="!bg-amber-300 dark:!bg-purple-300 dark:!text-neutral-800">
            <InformationCircleIcon class="h-8 w-8" />
            This user is not assigned to any Agencies or Lists.
        </Banner>
        
        <SlidePanel
            name="user-agency-form"
            @close="agencyUser = undefined">
            <UserAgencyForm
                :agency-user="agencyUser"
                namespace="user-agencies"
                :user="user"
                :user-agencies="userAgencies"
                @success="close('user-agency-form')" />
        </SlidePanel>

        <div class="flex flex-col gap-y-12">
            <SearchableListGroup
                ref="agenciesSearch"
                size="md"
                singular="agency"
                plural="agencies"
                namespace="agencies"
                :response="agencies"
                :icons="{
                    default: BuildingOfficeIcon
                }"
                delete-label="remove"
                :routes="{
                    index: route('users.show', { user: user.id }),
                    show: agency => route('agencies.show', { agency: agency.id })
                }"
                :can="{
                    view: agency => agency.can?.view,
                    update: agency => agency.can?.update,
                    delete: agency => agency.can?.delete,
                }"
                :sort-options="[
                    { label: 'Most Recent', value: 'agency_user.created_at,desc' },
                    { label: 'Oldest First', value: 'agency_user.created_at,asc' },
                    { label: 'Alphabetically (asc)', value: 'name,asc' },
                    { label: 'Alphabetically (desc)', value: 'name,desc' }
                ]"
                :description="model => `Updated on ${format(new Date(model.pivot.updated_at), 'PPPp')}`"
                :badges="agency => agency.pivot?.role ? [titleCase(agency.pivot.role)] : []">
                <template #create-action>
                    <button
                        class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                        @click="open('user-agency-form')">
                        <PlusCircleIcon class="h-full w-full" />
                    </button>
                </template>
                <template #list-actions-links="{ model: agency }">
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickUpdateAgencyUser(agency)">
                        <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                    </button>
                    <hr>
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickRemoveAgencyUser(agency)">
                        <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove Agency
                    </button>
                </template>
                <template #icon="{ model: agency }">
                    <div
                        class="relative">
                        <BuildingOfficeIcon class="h-8 w-8" />
                        <ExclamationCircleIcon
                            v-if="!agency.pivot?.accessed_at"
                            title="Never Accessed"
                            class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                    </div>
                </template>
            </SearchableListGroup>

            <SlidePanel
                name="user-list-form"
                @close="listUser = undefined; agencyUser = undefined">
                <UserListForm
                    :list-user="listUser"
                    :user="user"
                    :user-lists="userLists"
                    namespace="user-lists"
                    @success="close('user-list-form')" />
            </SlidePanel>

            <SearchableListGroup
                ref="listsSearch"
                size="md"
                singular="list"
                plural="lists"
                namespace="lists"
                :response="lists"
                :icons="{
                    default: UserGroupIcon
                }"
                :routes="{
                    index: route('users.show', { user: user.id }),
                    show: list => route('agencies.lists.show', {
                        agency: list.agency_id,
                        list: list.id
                    })
                }"
                :can="{
                    view: list => list.can?.view,
                    update: list => list.can?.update,
                    delete: list => list.can?.delete,
                }"
                :sort-options="[
                    { label: 'Most Recent', value: 'list_user.created_at,desc' },
                    { label: 'Oldest First', value: 'list_user.created_at,asc' },
                    { label: 'Alphabetically (asc)', value: 'name,asc' },
                    { label: 'Alphabetically (desc)', value: 'name,desc' }
                ]"
                :description="model => `Updated on ${format(new Date(model.pivot.updated_at), 'PPPp')}`"
                :badges="list => list.pivot?.role ? [titleCase(list.pivot.role)] : []">
                <template #create-action>
                    <button
                        class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                        @click="open('user-list-form')">
                        <PlusCircleIcon class="h-full w-full" />
                    </button>
                </template>
                <template #list-actions-links="{ model:list }">
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickUpdateListUser(list)">
                        <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                    </button>
                    <hr>
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickRemoveListUser(list)">
                        <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove List
                    </button>
                </template>
                <template #icon="{ model: list }">
                    <div
                        class="relative">
                        <UserGroupIcon class="h-8 w-8" />
                        <ExclamationCircleIcon
                            v-if="!list.pivot?.accessed_at"

                            title="Never Accessed"
                            class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                    </div>
                </template>
            </SearchableListGroup>

            <DetailsView
                :data="user"
                :fields="[
                    { name: 'logged_in_at', label: 'Last Login', type: Date, format: 'PPPp' },
                    { name: 'activated_at', label: 'Activated', type: Date, format: 'PPPp' },
                    { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                    { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
                    { name: 'timezone_label', label: 'Timezone' },
                ]" />

            <SearchableListGroup
                size="md"
                header="Activity"
                namespace="audits"
                singular="audit"
                plural="audits"
                :filters="false"
                :response="audits"
                :icons="{
                    default: ClipboardDocumentListIcon
                }">
                <template #icon="{ model }">
                    <IconDescription
                        v-if="model.description?.icon"
                        :description="model.description.icon" />
                </template>
                <template #badges="{ model }">
                    <BadgeDescription
                        v-if="model.description?.badge"
                        :description="model.description.badge" />
                </template>
                <template #title="{ model }">
                    <Description
                        v-if="model.description"
                        :description="model.description" />
                    <template v-else>
                        Audit description not available.
                    </template>
                </template>
            </SearchableListGroup>
        </div>
    </Show>
</template>