<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3';
import { SelectField } from '@vue-interface/select-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, AgencyUser, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    agency?: Agency
    agencyUser?: AgencyUser;
    user: User;
    userAgencies?: (Agency & { pivot: AgencyUser })[];
}>();

const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const method = props.agencyUser ? 'put' : 'post';

const url = props.agencyUser
    ? route('users.agencies.update', {
        user: props.user.id,
        agency: props.agencyUser.agency_id
    })
    : route('users.agencies.store', {
        user: props.user.id,
    });

const page = usePage<{
    queryParams: Record<string,any>
}>();

const form = useForm(method, url, {
    email: undefined,
    agency_id: props.agencyUser?.agency_id,
    role: props.agencyUser?.role,
    abilities: props.agencyUser?.abilities,
    queryParams: page.props.queryParams
});

function onSubmit() {
    activity.value = true;

    form.errors = {};
    form.submit({
        only: ['agencies', 'audits', 'user', 'userAgencies'],
        preserveScroll: true,
        onSuccess() {
            emit('success');
        },
        onError(errors) {
            emit('error', errors);
        },
        onFinish() {
            activity.value = false;
        }
    });
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ agencyUser?.role ? 'Edit Permissions' : 'Assign User' }}
            </h2>
        </div>
        <FormLayout
            :submit="onSubmit"
            :activity="activity"
            :disabled="activity"
            :button-block="true"
            :button-label="agencyUser?.role ? 'Save' : 'Submit'">
            <FormField
                v-if="!agencyUser"
                label="Name"
                description="The agency you wish to assign to the user."
                required
                block>
                <Autocomplete
                    id="agency_id"
                    v-model="form.agency_id"
                    name="agency_id"
                    namespace="user-agencies"
                    only="userAgencies"
                    :response="userAgencies"
                    :error="form.errors?.agency_id" />
            </FormField>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the agency."
                required
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :error="form.errors?.role">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
            <FormField
                v-if="form.role === 'user'"
                label="Abilities"
                description="The abilities give users privileges in the app."
                block>
                <UserAbilitiesField v-model="form.abilities" />
            </FormField>
        </FormLayout>
    </div>
</template>