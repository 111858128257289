<script lang="ts" setup>
import { SelectField } from '@vue-interface/select-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { ListUser, SubscriberList, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    list?: SubscriberList;
    listUser?: ListUser;
    user: User;
    userLists?: (SubscriberList & { pivot: ListUser })[];
}>();


const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const method = props.listUser ? 'put' : 'post';

const url = props.listUser
    ? route('users.lists.update', {
        user: props.user.id,
        list: props.listUser.list_id
    })
    : route('users.lists.store', {
        user: props.user.id,
    });

const form = useForm(method, url, {
    list_id: undefined,
    email: undefined,
    role: undefined,
    abilities: undefined,
    ...props.listUser
});

function onSubmit() {
    activity.value = true;

    form.errors = {};
    form.submit({
        only: ['lists', 'audits', 'userLists'],
        preserveScroll: true,
        onSuccess() {
            emit('success');
        },
        onError(errors) {
            emit('error', errors);
        },
        onFinish() {
            activity.value = false;
        }
    });
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ listUser?.role ? 'Edit Permissions' : 'Assign to List' }}
            </h2>
        </div>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            :button-label="listUser?.role ? 'Save' : 'Submit'"
            :disabled="activity"
            :activity="activity">
            <FormField
                v-if="!listUser?.role"
                label="Name"
                description="The list you wish to assign the user."
                required
                block>
                <Autocomplete
                    id="list_id"
                    v-model="form.list_id"
                    name="list_id"
                    namespace="user-lists"
                    only="userLists"
                    :description="model => model.agency?.name ?? ''"
                    :response="userLists"
                    :error="form.errors?.list_id" />
            </FormField>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the list."
                required
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :error="form.errors?.role">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
            <FormField
                v-if="form.role === 'user'"
                label="Abilities"
                description="The abilities give users privileges in the app."
                block>
                <UserAbilitiesField v-model="form.abilities" />
            </FormField>
        </FormLayout>
    </div>
</template>