<script lang="ts" setup>
import { AdjustmentsHorizontalIcon, ArchiveBoxIcon, Bars3Icon, ClipboardDocumentListIcon, ExclamationCircleIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import { SlidePanel, useSlidePanels } from '@vue-interface/slide-panel';
import type { Agency, Audit, LengthAwarePaginator, ListUser, SubscriberList, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { type ComponentExposed } from 'vue-component-type-helpers';
import AdminLayout from '../Layouts/AdminLayout.vue';
import ActionButton from '../components/ActionButton.vue';
import DetailsView, { Relationship } from '../components/DetailsView.vue';
import ListUserForm from '../components/ListUserForm.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import Tab from '../components/Tab.vue';
import Tabs from '../components/Tabs.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { titleCase } from '../composables/titleCase';
import { echo } from '../plugins/Echo';

export type ListShowProps = {
    agency: Agency,
    authUser: User;
    list: SubscriberList;
    listUsers?: (User & { pivot: ListUser })[];
    users?: LengthAwarePaginator<User>;
    audits?: LengthAwarePaginator<Audit>;
    queryParams?: Record<string,any>;
};

const props = defineProps<ListShowProps>();

defineOptions({
    layout: AdminLayout
});

const listUser = ref<ListUser>();
const listUsersSearch = ref<ComponentExposed<typeof SearchableListGroup>>();

const page = usePage<{
    queryParams: Record<string,any>
}>();

const { open, close } = useSlidePanels();

function onClickUpdateUser(user: User) {
    listUser.value = user.pivot;

    open('list-user-form');
}

function onClickRemoveUser(user: User) {
    console.log(page.props.queryParams);
    
    if(confirm(`Are you sure you want to remove ${user.email} from ${props.list.name}?`)) {
        const form = useForm({
            queryParams: props.queryParams
        });

        form.submit('delete', route('agencies.lists.users.destroy', {
            agency: props.agency.id,
            list: props.list.id,
            user: user.id,
        }), {
            preserveScroll: true,
            only: ['audits', 'users', 'listUsers'],
        });
    }
}

function onClickDeleteList() {
    if(confirm(`Are you sure you want to delete ${props.list.name}?`)) {
        router.delete(route('agencies.lists.destroy', { agency: props.agency.id, list: props.list.id }));
    }
}

onBeforeMount(() => {
    echo.private(`App.Models.SubscriberList.${props.list.id}`)
        .listen('.SubscriberListUpdated', () => router.reload())
        .listen('.SubscriberListDeleted', () => router.reload())
        .listen('.AuditCreated', () => router.reload())
        .listen('.AuditUpdated', () => router.reload())
        .listen('.AuditDeleted', () => router.reload())
        .listen('.ListUserCreated', () => listUsersSearch.value?.reload())
        .listen('.ListUserUpdated', () => listUsersSearch.value?.reload())
        .listen('.ListUserDeleted', () => listUsersSearch.value?.reload());
});

onBeforeUnmount(() => {
    echo.private(`App.Models.SubscriberList.${props.list.id}`)
        .stopListening('.SubscriberListUpdated')
        .stopListening('.SubscriberListDeleted')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted')
        .stopListening('.ListUserCreated')
        .stopListening('.ListUserUpdated')
        .stopListening('.ListUserDeleted');
});
</script>

<template>
    <Show
        :title="list.name">
        <template #icon>
            <UserGroupIcon class="h-12 w-12 dark:text-neutral-200 shrink-0" />
        </template>
        <template #actions>
            <ActionButton>
                <Link
                    v-if="list.can?.update"
                    :href="route('agencies.lists.edit', {
                        agency: agency.id,
                        list: list.id
                    })"
                    class="group flex items-center">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit List
                </Link>
                <Link
                    v-if="list.can?.updateAdvanced"
                    :href="route('agencies.lists.advanced', {
                        agency: agency.id,
                        list: list.id
                    })"
                    class="group flex items-center">
                    <AdjustmentsHorizontalIcon class="my-1 mr-3 h-5 w-5" /> Advanced Options
                </Link>
                <button
                    class="group flex items-center"
                    @click="open('list-user-form')">
                    <UserIcon class="my-1 mr-3 h-5 w-5" /> Assign User
                </button>
                <hr v-if="list.can?.delete">
                <button
                    v-if="list.can?.delete"
                    class="group flex items-center"
                    @click="onClickDeleteList">
                    <ArchiveBoxIcon class="my-1 mr-3 h-5 w-5" /> Archive List
                </button>
            </ActionButton>
        </template>

        <SlidePanel
            name="list-user-form"
            @close="listUser = undefined">
            <ListUserForm
                :agency="agency"
                :list="list"
                :list-user="listUser"
                :list-users="listUsers"
                namespace="list-users"
                @success="close('list-user-form')" />
        </SlidePanel>

        <SearchableListGroup
            ref="listUsersSearch"
            size="md"
            header="List Users"
            singular="user"
            plural="users"
            namespace="users"
            :response="users"
            delete-label="remove"
            :icons="{
                default: UserIcon
            }"
            :badges="(user: User) => user.pivot?.role ? [ titleCase(user.pivot.role)] : []"
            :can="{
                create: list.can?.create,
                view: list => list.can?.view
            }"
            :routes="{
                index: route('agencies.lists.show', { agency: agency.id, list: list.id }),
                show: user => route('users.show', { user: user.id })
            }">
            <template #create-action>
                <button
                    class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                    @click="open('list-user-form')">
                    <PlusCircleIcon class="h-full w-full" />
                </button>
            </template>
            <template #list-actions-links="{ model }">
                <button
                    class="group flex items-center capitalize"
                    @click="onClickUpdateUser(model)">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                </button>
                <hr>
                
                <button
                    class="group flex items-center capitalize"
                    @click="onClickRemoveUser(model)">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove User
                </button>
            </template>
            <template #icon="{ model }">
                <div
                    class="relative">
                    <UserIcon class="h-8 w-8" />
                    <ExclamationCircleIcon
                        v-if="!model.pivot?.accessed_at"
                        title="Never Accessed"
                        class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                </div>
            </template>
        </SearchableListGroup>

        <Tabs>
            <Tab
                header="Details"
                :icon="Bars3Icon">
                <DetailsView
                    :header="false"
                    :data="list"
                    :fields="[
                        list.agency?.can?.view ? { name: 'agency.name', label: 'Agency', type: Relationship, href: route('agencies.show', { agency: list.agency.id }) } : undefined,
                        list.instance?.can?.view ? { name: 'instance.name', label: 'Instance', type: Relationship, href: route('instances.show', { instance: list.instance.id }) } : undefined,
                        list.instance?.can?.view ? { name: 'catalog.name', label: 'Catalog' } : undefined,
                        list.instance?.can?.view ? { name: 'mg_campaign_id', label: 'MessageGears Campaign ID' } : undefined,
                        list.instance?.can?.view ? { name: 'folder.name', label: 'MessageGears Template Folder' } : undefined,
                        { name: 'options.mg_from_name', label: 'From Name' },
                        { name: 'options.mg_from_address', label: 'From Address' },
                        { name: 'options.mg_reply_to_address', label: 'Reply-To Address' },
                        authUser.super_admin ? { name: 'options.enable_cloudfront', label: 'Cloudfront Image CDN', type: Boolean } : undefined,
                        authUser.super_admin ? { name: 'options.enable_html', label: 'Allow HTML', type: Boolean } : undefined,
                        { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                        { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
                    ]" />
            </Tab>
            <Tab
                v-if="authUser.super_admin && (
                    list.options.custom_fonts?.some(item => !!item.url && !!item.font_family) ||
                    list.options.smart_merge_tags?.some(item => !!item.value) ||
                    list.options.mg_data_variables?.some(item => !!item.type) 
                )"
                header="Advanced"
                :icon="AdjustmentsHorizontalIcon">
                <DetailsView
                    :header="false"
                    :data="list.options"
                    :fields="[
                        list.options.custom_fonts?.some(item => !!item.url && !!item.font_family) ? { name: 'custom_fonts', label: 'Custom Fonts' } : undefined,
                        list.options.smart_merge_tags?.some(item => !!item.value) ? { name: 'smart_merge_tags', label: 'Smart Merge Tags' } : undefined,
                        list.options.mg_data_variables?.some(item => !!item.type) ? { name: 'mg_data_variables', label: 'MessageGears Data Variables' } : undefined,
                    ]">
                    <template #custom_fonts="{ data }">
                        <template v-for="item in data">
                            {{ item.url }}
                        </template>
                    </template>
                    <template #mg_data_variables="{ data }">
                        <template v-for="item in data">
                            <div
                                v-if="!!item.type"
                                :key="item.id">
                                {{ item.label }}
                            </div>
                        </template>
                    </template>
                    <template #smart_merge_tags="{ data }">
                        <template
                            v-for="(item, i) in data"
                            :key="`tag-${i}`">
                            <div>
                                {{ item.name }}
                            </div>
                        </template>
                    </template>
                </DetailsView>
            </Tab>
        </Tabs>

        <SearchableListGroup
            size="md"
            header="Activity"
            namespace="audits"
            :filters="false"
            :response="audits"
            :icons="{
                default: ClipboardDocumentListIcon
            }">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>