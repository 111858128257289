<script lang="ts" setup>
import { ArchiveBoxIcon, BuildingOfficeIcon, ClipboardDocumentListIcon, EnvelopeIcon, ExclamationCircleIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router, useForm } from '@inertiajs/vue3';
import { SlidePanel, useSlidePanels } from '@vue-interface/slide-panel';
import type { Agency, AgencyUser, Audit, Can, LengthAwarePaginator, SubscriberList, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import AdminLayout from '../Layouts/AdminLayout.vue';
import ActionButton from '../components/ActionButton.vue';
import AgencyUserForm from '../components/AgencyUserForm.vue';
import DetailsView, { Relationship } from '../components/DetailsView.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { titleCase } from '../composables/titleCase';
import { echo } from '../plugins/Echo';

const props = defineProps<{
    agency: Agency;
    agencyUsers?: (User & {pivot: AgencyUser})[];
    authUser: User;
    listCan: Can;
    lists?: LengthAwarePaginator<SubscriberList>;
    users?: LengthAwarePaginator<User & { pivot: AgencyUser }>;
    audits?: LengthAwarePaginator<Audit>;
    queryParams?: Record<string,any>;
}>();

defineOptions({
    layout: AdminLayout
});

const listSearch = ref<ComponentExposed<typeof SearchableListGroup>>();

const agencyUser = ref<AgencyUser|undefined>();
const agencyUsersSearch = ref<ComponentExposed<typeof SearchableListGroup>>();

const { open, close } = useSlidePanels();
    
function onClickUpdateAgencyUser(user: User & { pivot: AgencyUser }) {
    agencyUser.value = user.pivot;

    open('agency-user-form');
}

function onClickRemoveAgencyUser(user: User & { pivot: AgencyUser }) {
    if(confirm(`Are you sure you want to remove ${user.email} from ${props.agency.name}?`)) {
        const form = useForm({
            queryParams: props.queryParams
        });

        form.submit('delete', route('agencies.users.destroy', {
            agency: props.agency.id,
            user: user.id
        }), {
            preserveScroll: true,
            only: ['audits', 'users', 'agencyUsers'],
        });
    }
}

function onClickDelete() {
    if(confirm(`Are you sure you want to delete ${props.agency.name}?`)) {
        router.delete(route('agencies.destroy', { agency: props.agency.id }), {
            preserveScroll: true,
        });
    }
}

function onClickSendActivation(user: User & { pivot: AgencyUser }) {
    if(confirm('Are you sure you want to send another activation email?')) {
        router.put(route('users.activation-email', { user: user.id }), {
            preserveScroll: true,
        });
    }
}

onBeforeMount(() => {
    echo.private(`App.Models.Agency.${props.agency.id}`)
        .listen('.AgencyUpdated', () => router.reload())
        .listen('.AgencyDeleted', () => router.reload())
        .listen('.AuditCreated', () => router.reload())
        .listen('.AuditUpdated', () => router.reload())
        .listen('.AuditDeleted', () => router.reload())
        .listen('.SubscriberListCreated', () => listSearch.value?.reload())
        .listen('.SubscriberListUpdated', () => listSearch.value?.reload())
        .listen('.SubscriberListDeleted', () => listSearch.value?.reload())
        .listen('.AgencyUserCreated', () => agencyUsersSearch.value?.reload())
        .listen('.AgencyUserUpdated', () => agencyUsersSearch.value?.reload())
        .listen('.AgencyUserDeleted', () => agencyUsersSearch.value?.reload())
        .listen('.InstanceUpdated', () => router.reload())
        .listen('.InstanceDeleted', () => router.reload());
});

onBeforeUnmount(() => {
    echo.private(`App.Models.Agency.${props.agency.id}`)
        .stopListening('.AgencyUpdated')
        .stopListening('.AgencyDeleted')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted')
        .stopListening('.SubscriberListCreated')
        .stopListening('.SubscriberListUpdated')
        .stopListening('.SubscriberListDeleted')
        .stopListening('.AgencyUserCreated')
        .stopListening('.AgencyUserUpdated')
        .stopListening('.AgencyUserDeleted')
        .stopListening('.InstanceUpdated')
        .stopListening('.InstanceDeleted');
});
</script>

<template>
    <Show
        :icon="BuildingOfficeIcon"
        :title="agency.name">
        <template #actions>
            <ActionButton>
                <Link
                    v-if="agency.can?.update"
                    :href="route('agencies.edit', { agency: agency.id })"
                    class="group flex items-center">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Agency
                </Link>
                <Link
                    v-if="agency.can?.create"
                    :href="route('agencies.lists.create', { agency: agency.id })"
                    class="group flex items-center">
                    <UserGroupIcon class="my-1 mr-3 h-5 w-5" /> Create List
                </Link>
                <button
                    class="group flex items-center"
                    @click="open('agency-user-form')">
                    <UserIcon class="my-1 mr-3 h-5 w-5" /> Assign User
                </button>
                <hr
                    v-if="agency.can?.delete">
                <button
                    v-if="agency.can?.delete"
                    class="group flex items-center"
                    @click="onClickDelete">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete Agency
                </button>
            </ActionButton>
        </template>

        <SlidePanel
            name="agency-user-form"
            @close="agencyUser = undefined">
            <AgencyUserForm
                :agency="agency"
                :agency-user="agencyUser"
                :agency-users="agencyUsers"
                namespace="agency-users"
                @success="close('agency-user-form')" />
        </SlidePanel>

        <SearchableListGroup
            ref="listSearch"
            singular="list"
            plural="lists"
            namespace="lists"
            delete-label="Archive"
            :delete-icon="ArchiveBoxIcon"
            :response="lists"
            :icons="{
                default: UserGroupIcon
            }"
            :routes="{
                index: route('agencies.show', { agency: agency.id }),
                show: list => route('agencies.lists.show', {
                    agency: agency.id,
                    list: list.id
                }),
                edit: list => route('agencies.lists.edit', {
                    agency: agency.id,
                    list: list.id
                }),
                delete: list => route('agencies.lists.destroy', {
                    agency: agency.id,
                    list: list.id
                }),
            }"
            :can="{
                view: list => list.can?.view,
                update: list => list.can?.update,
                delete: list => list.can?.delete,
                create: listCan.create
            }">
            <template #create-action>
                <Link
                    v-if="listCan.create"
                    :href="route('agencies.lists.create', { agency: agency.id })"
                    class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200">
                    <PlusCircleIcon class="h-full w-full" />
                </Link>
            </template>
        </SearchableListGroup>

        <SearchableListGroup
            ref="agencyUsersSearch"
            header="Agency Users"
            singular="user"
            plural="users"
            namespace="users"
            :only="['users']"
            :response="users"
            delete-label="remove"
            :icons="{
                default: UserIcon
            }"
            :routes="{
                index: route('agencies.show', { agency: agency.id }),
                show: user => route('users.show', { user: user.id })
            }"
            :can="{
                view: user => user.can?.view
            }"
            :title="(user) => user.name || user.email"
            :description="(user) => user.name && user.email"
            :badges="(user) => user.pivot?.role ? [titleCase(user.pivot.role)] : []">
            <template #create-action>
                <button
                    class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                    @click="open('agency-user-form')">
                    <PlusCircleIcon class="h-full w-full" />
                </button>
            </template>
            <template #list-actions-links="{ model:user }">
                <button
                    class="group flex items-center capitalize"
                    @click="onClickUpdateAgencyUser(user)">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                </button>
                <button
                    v-if="!user.activated_at"
                    class="group flex items-center capitalize"
                    @click="onClickSendActivation(user)">
                    <EnvelopeIcon class="my-1 mr-3 h-5 w-5" /> Re-Send Activation Email
                </button>
                <hr>
                <button
                    class="group flex items-center capitalize"
                    @click="onClickRemoveAgencyUser(user)">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove User
                </button>
            </template>
            <template #icon="{ model: user }">
                <div
                    class="relative">
                    <UserIcon class="h-8 w-8" />
                    <ExclamationCircleIcon
                        v-if="!user.pivot?.accessed_at"
                        title="Never Accessed"
                        class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                </div>
            </template>
        </SearchableListGroup>

        <DetailsView
            header="Details"
            :data="agency"
            :fields="[
                agency.instance.can?.view ? { name: 'instance.name', label: 'Instance', href: route('instances.show', { instance: agency.instance.id }), type: Relationship } : undefined,
                { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
                { name: 'updated_at', label: 'Last Updated', type: Date, format: 'PPPp' }
            ]" />

        <SearchableListGroup
            size="md"
            header="Activity"
            namespace="audits"
            :filters="false"
            :response="audits"
            :icons="{
                default: ClipboardDocumentListIcon
            }">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>