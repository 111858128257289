<script lang="ts" setup>
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { InputField } from '@vue-interface/input-field';
import { ref } from 'vue';

defineProps<{
    activity?: boolean
}>();

const el = ref<typeof InputField>();
const model = defineModel<string>();

const emit = defineEmits<{
    'reset': []
}>();

function reset() {
    model.value = undefined;

    emit('reset');

    // @ts-ignore
    el.value.$el.querySelector('input')?.focus();
}
</script>

<template>
    <InputField
        id="search"
        ref="el"
        v-model="model"
        autocomplete="off"
        :activity="activity"
        type="text"
        name="search"
        placeholder="Search…">
        <template #icon>
            <MagnifyingGlassIcon class="h-6 w-6" />
        </template>
        <template #activity>
            <button
                v-if="model?.length"
                class="absolute outline-none top-0 right-2 h-full dark:text-neutral-400 dark:hover:text-neutral-300  dark:focus:text-neutral-300 dark:active:text-neutral-200"
                @click="reset">
                <XCircleIcon class="w-5 h-5" />
            </button>
        </template>
    </InputField>
</template>