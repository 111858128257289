<script lang="ts" setup>
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import type { DatePickerMarker } from '@vuepic/vue-datepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useColorMode } from '@vueuse/core';
import { addMinutes, addSeconds, format, isBefore } from 'date-fns';
import { useForm } from 'laravel-precognition-vue-inertia';
import colors from 'tailwindcss/colors';
import type { Agency, Send, SubscriberList } from 'types';
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { route } from '../composables/routes';
import type { ScheduledSends } from '../Pages/SendShow.vue';

const props = defineProps<{
    agency: Agency
    list: SubscriberList
    send: Send
    description: string;
    scheduled?: ScheduledSends;
}>();

const scheduledMarkers = ref<DatePickerMarker[]>([]);

watchEffect(() => {
    if(!props.scheduled) {
        return;
    }

    scheduledMarkers.value = props.scheduled?.markers.map(marker => {
        return {
            ...marker,
            color: colors.rose[500],
            tooltip: marker.tooltip?.map(tooltip => {
                return {
                    ...tooltip,
                    color: colors.rose[500]
                };
            })
        };
    });

});

const mode = useColorMode();

const defaultDateTime = addMinutes(new Date(), 10);

const url = route('sends.schedule', { 
    agency: props.agency.slug,
    list: props.list.slug,
    send: props.send.id
});

const form = useForm('patch', url, {
    send_at: defaultDateTime.toISOString(),
});

let fiveMinutesFromNow = ref(addMinutes(new Date(), 5));

const interval = setInterval(() => {
    fiveMinutesFromNow.value = addSeconds(new Date(fiveMinutesFromNow.value), 1);
}, 1000);

const isTimeValid = computed(() => {
    const now = new Date(new Date(form.send_at).toLocaleString());

    if(!props.scheduled) {
        return true;
    }

    for(const send of props.scheduled.sends) {
        if(send.id === props.send.id) {
            return true;
        }

        if(!send.scheduled_block?.start || !send.scheduled_block?.end) {
            continue;
        }

        const start = new Date(new Date(send.scheduled_block.start).toLocaleString());
        const end = new Date(new Date(send.scheduled_block.end).toLocaleString());
        
        if(start <= now && end >= now) {
            return false;
        }
    }

    if(isBefore(now, fiveMinutesFromNow.value)) {
        return false;
    }

    return true;
});

onMounted(() => {
    router.reload({
        only: ['scheduled']
    });
});

onUnmounted(() => {
    clearInterval(interval);
});

defineExpose({
    form,
    url
});
</script>

<template>
    <div
        class="flex flex-col gap-4">
        <h1>
            {{ description }}
        </h1>

        <VueDatePicker
            v-model="form.send_at"
            inline
            :markers="scheduledMarkers"
            :is-24="false"
            auto-apply
            :month-change-on-scroll="false"
            :dark="mode === 'dark'" />

        <div
            v-if="form.errors.send_at"
            class="text-red-500">
            {{ form.errors.send_at }}
        </div>

        <div
            class="p-2 rounded flex gap-x-2 justify-start items-center text-lg text-white"
            :class="{
                'bg-emerald-500': isTimeValid,
                'bg-red-500': !isTimeValid
            }">
            <template v-if="isTimeValid">
                <CheckCircleIcon
                    class="h-8 w-8 shrink-0" />
                {{ format(new Date(form.send_at), 'PPPp') }}
            </template>
            <template v-else>
                <ExclamationCircleIcon
                    class="w-8 h-8 shrink-0" />
                {{ format(new Date(form.send_at), 'PPPp') }}
            </template>
        </div>
    </div>
</template>

<style>
.dp__flex_display {
    display: block !important;
}

.dp__menu {
    border: none !important;
}

.dp__menu {
    border: none;
}

.dp__menu {
    border: none;
}
</style>