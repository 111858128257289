<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Instance } from 'types';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';

const props = defineProps<{
    url: string;
    instance?: Instance;
}>();

const method = props.instance?.id ? 'put' : 'post';

const form = useForm(method, props.url, {
    name: props.instance?.name,
    base_uri: props.instance?.base_uri,
    account_id: props.instance?.account_id,
    api_key: props.instance?.api_key,
    cloud_account_id: props.instance?.cloud_account_id,
    cloud_api_key: props.instance?.cloud_api_key,
    transactional_id: props.instance?.transactional_id,
    options: {
        table_suffix: props.instance?.options.table_suffix
    }
});
</script>

<template>
    <FormLayout
        :submit="form.submit"
        :button-label="!instance?.id ? 'Submit' : 'Save'"
        :activity="form.processing"
        :disabled="form.processing">
        <Card>
            <FormField
                label="Name"
                description="The name of the MessageGears instance."
                required>
                <InputField
                    id="name"
                    v-model="form.name"
                    name="name"
                    :error="form.errors.name"
                    @change="form.validate('name')" />
            </FormField>
            <FormField
                label=" Accelerator Base URI"
                description="The Base URI for the MessageGears Accelerator server."
                required>
                <InputField
                    id="base_uri"
                    v-model="form.base_uri"
                    name="base_uri"
                    :error="form.errors.base_uri" />
            </FormField>
            <FormField
                label="Accelerator API Key"
                description="The API key for the MessageGears Accelerator server."
                required>
                <InputField
                    id="api_key"
                    v-model="form.api_key"
                    name="api_key"
                    :error="form.errors.api_key" />
            </FormField>
            <FormField
                label="Accelerator Account ID"
                description="The Account ID for the MessageGears Accelerator server."
                required>
                <InputField
                    id="account_id"
                    v-model="form.account_id"
                    name="account_id"
                    :error="form.errors.account_id" />
            </FormField>
            <FormField
                label="Cloud API Key"
                description="The API key for the MessageGears Cloud server."
                required>
                <InputField
                    id="cloud_api_key"
                    v-model="form.cloud_api_key"
                    name="cloud_api_key"
                    :error="form.errors.cloud_api_key" />
            </FormField>
            <FormField
                label="Cloud Account ID"
                description="The account ID for the MessageGears Cloud server."
                required>
                <InputField
                    id="cloud_account_id"
                    v-model="form.cloud_account_id"
                    name="cloud_account_id"
                    :error="form.errors.cloud_account_id" />
            </FormField>
            <FormField
                label="Transactional Campaign ID"
                description="The transactional campaign id used to send all the transactional email for this instance."
                required>
                <InputField
                    id="transactional_id"
                    v-model="form.transactional_id"
                    name="transactional_id"
                    :error="form.errors.transactional_id" />
            </FormField>
            <FormField
                label="ListElixr Table Suffix"
                description="The suffix of the tables in elixr. If tables have no suffix, leave this field blank. Keep in mind that new listelixr tables need permissions granted.">
                <InputField
                    id="options.table_suffix"
                    v-model="form.options.table_suffix"
                    name="options.table_suffix"
                    :error="(form.errors as Record<string,string>)['options.table_suffix']" />
            </FormField>
        </Card>
    </FormLayout>
</template>